const middleware = {}

middleware['checkout'] = require('../middleware/checkout.ts')
middleware['checkout'] = middleware['checkout'].default || middleware['checkout']

middleware['cookie-banner'] = require('../middleware/cookie-banner.ts')
middleware['cookie-banner'] = middleware['cookie-banner'].default || middleware['cookie-banner']

middleware['ip-geolocation'] = require('../middleware/ip-geolocation.ts')
middleware['ip-geolocation'] = middleware['ip-geolocation'].default || middleware['ip-geolocation']

middleware['is-authenticated'] = require('../middleware/is-authenticated.ts')
middleware['is-authenticated'] = middleware['is-authenticated'].default || middleware['is-authenticated']

middleware['lunch-popup'] = require('../middleware/lunch-popup.ts')
middleware['lunch-popup'] = middleware['lunch-popup'].default || middleware['lunch-popup']

middleware['mbc-auth'] = require('../middleware/mbc-auth.ts')
middleware['mbc-auth'] = middleware['mbc-auth'].default || middleware['mbc-auth']

middleware['url-resolver'] = require('../middleware/url-resolver.ts')
middleware['url-resolver'] = middleware['url-resolver'].default || middleware['url-resolver']

export default middleware
